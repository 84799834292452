<template>
  <div ref="parent">
    <a-modal
      centered
      :width="isAdd ? 534 : 560"
      :title="modalTitle"
      :visible="visible"
      @cancel="handleCancel"
      :getContainer="() => $refs.parent"
    >
      <a-form ref="formRef" v-if="isAdd" :rules="rules" :model="formData">
        <a-form-item label="组织名称" name="department" class="form-item" :labelCol="{ span: 4 }">
          <a-input
            :maxlength="30"
            placeholder="请输入组织名称"
            v-model:value="formData.department"
            class="form-item-width"
          />
        </a-form-item>
        <a-form-item
          label="组织类型"
          v-if="enterpriseNature === 2 && type != 'addChild'"
          name="type"
          class="form-item"
          :labelCol="{ span: 4 }"
        >
          <a-radio-group v-model:value="formData.type" @change="typeChange" name="radioGroup">
            <a-radio :value="1">部门</a-radio>
            <a-radio :value="3">{{ selfType == 1 ? '公司' : '单位' }}</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="上级组织" name="parentId" class="form-item" :labelCol="{ span: 4 }">
          <SelectToModel
            @click="openRangeModal(2)"
            :list="parentDdepartment"
            @change="val => selectToChange(val, 2)"
            placeholder="请选择上级组织"
          />
        </a-form-item>
        <a-form-item label="主管" class="form-item" :labelCol="{ span: 4 }">
          <SelectToModel
            @click="openRangeModal(1)"
            :list="leaderSelectedList"
            @change="val => selectToChange(val, 1)"
          />
        </a-form-item>
      </a-form>
      <section v-else>
        <a-form-item label="下载模板" style="margin-bottom: 8px">
          <a @click="downLoadExample" style="color: #1890ff">点此下载批量导入Excel模板文件</a>
        </a-form-item>
        <!-- <a-form-item label="上传模板" style="margin-bottom:0"> -->
        <DraggerUpload
          uploadText="仅支持xls,xlsx格式的文档，单个文件大小不能超过10M"
          v-model:fileList="fileList"
          :callback="getFile"
          :tips="false"
          ref="uploadRef"
        />
        <p class="tips">请下载模板，使用Excel等软件按模板的格式进行填写。不得删除、修改excel列顺序</p>
        <!-- </a-form-item> -->
      </section>
      <template #footer>
        <a-button @click="handleCancel">取消</a-button>
        <a-button class="comfirm" @click="comfirmAdd" :loading="loading">确定</a-button>
      </template>
    </a-modal>
    <a-modal
      centered
      :title="selectorTitle"
      width="655px"
      class="global-range-modal"
      :visible="globalSelectorVisible"
      @cancel="globalSelectorCancel"
      @ok="globalSelectorOk"
    >
      <GlobalSelector
        :visible="globalSelectorVisible"
        :selectedList="selectedList"
        :selectorInfo="selectorInfo"
        :renderList="renderList"
        @getSearchInfo="getSearchInfo"
        @toNext="toNext"
        ref="globalSelector"
        @changeData="changeData"
      >
      </GlobalSelector>
    </a-modal>
  </div>
</template>

<script>
import { computed, defineComponent, reactive, ref, toRaw, watch, toRefs, onMounted } from 'vue'
import DraggerUpload from '@/components/Upload/draggerUpload'
import { cmsNotice } from '@/utils/utils'
import cloneDeep from 'lodash/cloneDeep'
import SelectToModel from '@/components/SelectToModel'
import { InfoCircleOutlined } from '@ant-design/icons-vue'
import { formateListData, transformData } from '@/utils/selectorListFormate.js'
import { getDepartmentOrStaff } from '@/apis/businessManage'
import GlobalSelector from '@/components/GlobalSelector/index.vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'departmentModal',
  components: {
    DraggerUpload,
    SelectToModel,
    // eslint-disable-next-line vue/no-unused-components
    InfoCircleOutlined,
    GlobalSelector
  },
  props: {
    visible: {
      type: Boolean,
      default: true
    },
    isAdd: {
      type: Boolean,
      default: true
    },
    departmentList: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: 'add'
    },
    current: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    },
    departmentId: {
      type: Number,
      default: () => undefined
    },
    departmentInfo: {
      type: Object,
      default: () => null
    }
  },
  setup(props, ctx) {
    const selectorInfo = ref({})
    const renderList = ref([])
    const globalSelector = ref(null)
    const formRef = ref()
    const uploadRef = ref()
    let fileRef = ref()
    const options = ref([])
    const { emit } = ctx
    const modalTitle = computed(() => {
      const titleObj = {
        addChild: '添加组织',
        add: '添加组织',
        edit: '修改组织',
        export: '导入组织'
      }
      return titleObj[props.type]
    })
    const store = useStore()
    const state = reactive({
      enterpriseNature: JSON.parse(localStorage.getItem('yda-admin-userInfo')).result.enterpriseNature, // 1公司 2集团
      enterpriseId: JSON.parse(localStorage.getItem('yda-admin-userInfo')).result.enterpriseId,
      selfType: JSON.parse(localStorage.getItem('yda-admin-userInfo')).result.selfType,
      selectedList: [],
      parentDdepartment: [],
      leaderSelectedList: [],
      globalSelectorVisible: false,
      fileList: [],
      count: 1,
      file: null,
      size: 50,
      selectType: 1,
      selectorTitle: '请选择主管'
    })

    const formData = reactive({
      departmentName: undefined,
      type: 1,
      parentId: undefined,
      leaderId: undefined
    })
    const rules = {
      department: [
        {
          required: true,
          message: '请输入组织名称'
        },
        {
          pattern: '^[\u9FA6-\u9FCB\u3400-\u4DB5\u4E00-\u9FA5_a-zA-Z0-9《》/！“”（）【】<>/!""()\\[\\]-]+$',
          message: '组织名称不能有空格,特殊字符',
          trigger: 'change'
        }
      ],
      type: [
        {
          required: true,
          message: '请选择组织类型',
          type: 'number'
        }
      ],
      parentId: [
        {
          required: true,
          message: '请选择上级组织',
          type: 'number'
        }
      ]
    }

    const openRangeModal = async type => {
      state.selectType = type
      //初始化对应数据
      getScopeList(
        {
          departmentId: type == 1 ? 0 : -1
        },
        type
      )
      //跟新弹窗功能配置
      if (type == 1) {
        selectorInfo.value = {
          placeholder: '请输入员工姓名或部门',
          limitCount: 1,
          ableSelectDep: true,
          abelCheckAll: true,
          ableChangeData: true
        }
        state.selectorTitle = '请选择主管'

        state.selectedList = state.leaderSelectedList
      }

      //上级组织
      if (type == 2) {
        selectorInfo.value = {
          placeholder: '请输入部门',
          limitCount: 1,
          ableSelectDep: true,
          isSelectDept: true,
          limitWarningInfo: '当前已添加至部门上限请删除已选部门后重试',
          customDefaultBread: [{ id: -1, label: '集团' }]
        }
        state.selectorTitle = '请选择上级组织'
        state.selectedList = state.parentDdepartment
      }
      state.globalSelectorVisible = true
    }

    const handleCancel = () => {
      formRef.value?.resetFields && formRef.value.resetFields()
      emit('update:visible', false)
    }
    const downLoadExample = () => {
      cmsNotice('success', '正在为您下载，请耐心等待...')
      window.location.href = `${process.env.VUE_APP_API_BASE_URL}/excel/组织导入模板.xls`
    }

    // 部门级联选择器显示最后一级部门
    const displayRender = ({ labels }) => {
      return labels[labels.length - 1]
    }
    // 获取部门id路径
    const getIds = id => {
      let ids = []
      let current = null
      const fn = arr => {
        arr.some(item => {
          if (item.departmentId == id) {
            current = item
            ids.push(id)
            return true
          }
          if (item?.childrenDepartmentIdSet) {
            ids.push(item.departmentId)
            return fn(item.childrenDepartmentList)
          }
          return false
        })
      }
      fn(options.value)
      if (!current || (current && !current.canCreateStaff)) {
        ids = [0]
      }
      return { ids, current }
    }

    //上级组织
    const setParentDdepartment = departmentId => {
      const { current } = getIds(departmentId)
      const parentDdepartment = current
        ? {
            data: {
              departmentList: [
                { departmentId: current.departmentId, departmentName: current.departmentName, dataType: 2 }
              ]
            }
          }
        : null
      state.parentDdepartment = parentDdepartment ? formateListData(parentDdepartment, 1) : []
      formData.parentId = current ? current.departmentId : undefined
    }

    watch(
      () => props.visible,
      newVisible => {
        if (!newVisible) {
          formRef.value?.resetFields()
          state.selectedList = []
          state.parentDdepartment = []
          state.leaderSelectedList = []
          return
        }
        //组织架构树形结构数据
        options.value = cloneDeep(props.departmentList)

        if (props.type === 'add' || props.type === 'addChild') {
          formData.type = 1
          formData.department = undefined
          const departmentId = props.type === 'add' ? -1 : +props.current.departmentId
          setParentDdepartment(departmentId)
        } else if (props.type === 'edit') {
          formData.department = props.current.departmentName
          console.log(+props.current.parentId)

          //上级组织
          setParentDdepartment(props.current.parentId)
          //组织类型
          formData.type = props.current.type
          //主管
          state.selectedList = state.leaderSelectedList = props.current.leaderId
            ? [
                {
                  dataName: props.current.leaderName,
                  dataId: props.current.leaderId,
                  dataType: 1,
                  icon: props.current?.fullAvatar ?? require('@/assets/images/default-avatar.png'),
                  // TODO:回显手机号
                  mobile: props.current?.mobile
                }
              ]
            : []
        } else if (props.type === 'export') {
          state.fileList = []
        }
      }
    )
    const comfirmAdd = () => {
      emit('update:loading', true)
      if (props.isAdd) {
        console.log(state.leaderSelectedList.length, state.parentDdepartment.length)
        formRef.value
          .validate()
          .then(() => {
            formData.nextDepart = state.parentDdepartment.length ? state.parentDdepartment[0].dataId : undefined
            formData.leaderId = state.leaderSelectedList.length ? state.leaderSelectedList[0].dataId : undefined
            emit('modalSubmit', toRaw(formData))
          })
          .catch(() => emit('update:loading', false))
      } else {
        const isSuccess = uploadRef.value.validateFile()
        if (!isSuccess) {
          emit('update:loading', false)
          return
        }
        emit('modalSubmit', fileRef.value)
      }
    }
    const getFile = file => {
      console.log('弹窗组件', file)
      fileRef.value = file
    }
    //切换组织类型
    const typeChange = e => {}
    //弹窗确实事件
    const globalSelectorOk = () => {
      let arr = [...globalSelector.value.okEvent()]
      if (state.selectType == 1) {
        transformData(arr).then(res => {
          state.leaderSelectedList = res
        })
      } else {
        state.parentDdepartment = [...arr]
        formData.parentId = state.parentDdepartment.length ? state.parentDdepartment[0].dataId : undefined
        console.log(formData.parentId)
      }
      globalSelectorCancel()
    }
    //弹窗取消事件
    const globalSelectorCancel = () => {
      state.globalSelectorVisible = false
      globalSelector.value.cancelEvent()
    }
    //去下级
    const toNext = id => {
      let data = {
        departmentId: id
      }
      getScopeList(data)
    }
    //搜索
    const getSearchInfo = async e => {
      let data = {
        searchName: e
      }
      if (!e) data.departmentId = state.selectType == 2 ? -1 : 0
      // 开启切换数据时，不传部门id
      if (selectorInfo.value.ableChangeData) data.departmentId = undefined
      getScopeList(data)
    }
    // 处理左侧数据
    const handelData = res => {
      renderList.value = formateListData(res)
      // console.log('处理后的renderList的值', renderList.value)
    }
    //弹窗数据
    const getScopeList = async data => {
      let params = {
        selectorType: state.selectType //1部门人员2部门3员工
      }

      const res = await getDepartmentOrStaff({ ...params, ...data })
      if (res.success) {
        console.log('全部数据', res)
        handelData(res)
      }
    }

    //清空
    const selectToChange = (val, type) => {
      if (type == 1) {
        state.leaderSelectedList = val
      } else {
        state.parentDdepartment = val
        formData.parentId = undefined
      }
    }

    // 切换数据
    const changeData = ({ isOrganizationData, searchValue }) => {
      console.log('切换的数据的类型', isOrganizationData)
      if (isOrganizationData) {
        // 组织架构数据
        state.selectType = 1
      } else {
        //人员数据
        state.selectType = 3
      }
      getSearchInfo(searchValue)
    }

    return {
      options,
      formData,
      modalTitle,
      ...toRefs(state),
      formRef,
      fileRef,
      rules,
      comfirmAdd,
      uploadRef,
      downLoadExample,
      handleCancel,
      getFile,
      displayRender,
      openRangeModal,
      typeChange,
      globalSelectorOk,
      globalSelectorCancel,
      globalSelector,
      selectorInfo,
      renderList,
      getSearchInfo,
      toNext,
      selectToChange,
      changeData
    }
  }
})
</script>

<style lang="scss" scoped>
.comfirm {
  background: #c3161c;
  color: #fff;
  border: 1px solid transparent;
  margin-right: 0;
  &:hover {
    border-color: transparent;
  }
}
:deep(.ant-select) {
  .ant-select-selector {
    height: 36px;
    border-radius: 4px;
    line-height: 36px;
  }
  .ant-select-selection-placeholder {
    line-height: 36px;
  }
  .ant-select-selection-item {
    line-height: 36px;
  }
}
.department-view {
  color: red;
}

.form-item {
  margin-bottom: 16px;
}
// :deep(.ant-form-item-with-help) {
//   margin-bottom: 0px;
// }

.form-item-width {
  width: 400px;
}
.tips {
  width: 512px;
  font-size: 14px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  text-align: justify;
  color: rgba(0, 0, 0, 0.65);
  line-height: 24px;
}
.orgname {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 486px;
  height: 90px;
  padding: 16px;
  background: #fbfbfb;
}

:deep(.ant-upload) {
  width: auto;
  height: auto;
}
</style>
<style lang="scss">
.depatment-cascader {
  .ant-cascader-menu-item {
    width: 6.2em;
    box-sizing: content-box;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
