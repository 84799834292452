<!-- 刘志坤 -->
<!-- 盖印人鼠标移入展示 -->
<template>
  <div class="form-tips-view">
    <div
      @click="handleDepartment"
      :class="['role-box', mode === 'single' && list.length ? 'singleBox' : '']"
      :style="{
        padding: mode === 'multiple' && list.length ? '6px 24px 1px 10px' : '0 0 0 12px',
        'line-height': list.length ? 'auto' : '32px',
        maxHeight: '100px',
        overflow: 'auto',
        width: width + 'px'
      }"
    >
      <template v-if="list.length">
        <div v-if="mode === 'single'" class="role-name-single">
          {{ list[0].dataName }}
        </div>
        <template v-else>
          <div v-for="(item, index) in list" :key="item.dataId" class="role-tag">
            <span class="role-name-multiple" :title="item.dataName">{{ item.dataName }}</span>
            <div @click.stop="deleteSta(index)" class="role-close">
              <CloseOutlined />
            </div>
          </div>
        </template>
      </template>
      <span v-else>{{ placeholder }}</span>
      <CloseCircleFilled class="down-close" v-if="mode === 'single'" @click.stop="deleteSta(0)" />
      <DownOutlined class="down-arrow" />
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, watch } from 'vue'
import {
  LeftOutlined,
  CloseOutlined,
  DownOutlined,
  ExclamationOutlined,
  CloseCircleFilled
} from '@ant-design/icons-vue'
export default {
  name: 'SelectToModel',
  components: { LeftOutlined, CloseOutlined, DownOutlined, ExclamationOutlined, CloseCircleFilled },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    width: {
      type: String,
      default: '400'
    },
    mode: {
      type: String, //'single'或"multiple"
      default: 'single'
    },
    placeholder: {
      type: String, //'single'或"multiple"
      default: '请选择主管'
    }
  },
  setup(props, { emit }) {
    const state = reactive({
      list: props.list
    })
    watch(
      () => props.list,
      newValue => {
        state.list = newValue
      }
    )
    const handleDepartment = () => {
      emit('click')
    }
    const deleteSta = index => {
      state.list.splice(index, 1)
      emit('change', state.list)
    }
    return {
      ...toRefs(state),
      handleDepartment,
      deleteSta
    }
  }
}
</script>
<style lang="less" scoped>
// 蒙层展示
.form-tips-view {
  display: flex;
  align-items: center;
  margin-top: 4px;
}
.singleBox {
  &:hover {
    .down-close {
      display: block;
    }
    .down-arrow {
      display: none;
    }
  }
}

.role-box {
  border: 1px solid #d9d9d9;
  border-radius: 1px;
  line-height: 32px;
  color: #bfbfbf;
  position: relative;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  padding: 6px 24px 1px 10px;
  transition: border-color 0.3s;
  &::-webkit-scrollbar {
    display: block;
  }
  &:hover {
    border-color: #cf3838;
  }
  .role-tag {
    // width: 90px;
    white-space: nowrap;
    background: #fafafa;
    border: 1px solid #d9d9d9;
    padding: 0 5px 0 7px;
    font-size: 12px;
    line-height: 20px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 10px;
    margin-bottom: 5px;
  }
  .role-name-single {
    color: rgba(0, 0, 0, 0.85);
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: 7px;
    white-space: nowrap;
    display: inline-block;
    flex: 1;
    cursor: default;
  }
  .role-name-multiple {
    color: rgba(0, 0, 0, 0.85);
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: 7px;
    white-space: nowrap;
    display: inline-block;
    flex: 1;
    cursor: default;
  }
  .role-close {
    width: 14px;
    height: 14px;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      color: #333;
    }
  }
  .down-arrow {
    position: absolute;
    font-size: 12px;
    top: 50%;
    transform: translateY(-50%);
    right: 11px;
    color: rgba(0, 0, 0, 0.3);
  }
  .down-close {
    position: absolute;
    font-size: 12px;
    top: 50%;
    transform: translateY(-50%);
    right: 11px;
    color: rgba(0, 0, 0, 0.3);
    display: none;
  }
}
</style>
